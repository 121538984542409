@font-face{
  font-family:"FrutigerNeueLTW04-Book";
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-Book.eot?#iefix");
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-Book.eot?#iefix") format("eot"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Book.woff2") format("woff2"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Book.woff") format("woff"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Book.ttf") format("truetype");
}
@font-face{
  font-family:"FrutigerNeueLTW04-BookIt";
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-BookIt.eot?#iefix");
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-BookIt.eot?#iefix") format("eot"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-BookIt.woff2") format("woff2"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-BookIt.woff") format("woff"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-BookIt.ttf") format("truetype");
}
@font-face{
  font-family:"FrutigerNeueLTW04-Bold";
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-Bold.eot?#iefix");
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-Bold.eot?#iefix") format("eot"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Bold.woff2") format("woff2"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Bold.woff") format("woff"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Bold.ttf") format("truetype");
}
@font-face{
  font-family:"FrutigerNeueLTW04-Heavy";
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-Heavy.eot?#iefix");
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-Heavy.eot?#iefix") format("eot"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Heavy.woff2") format("woff2"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Heavy.woff") format("woff"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Heavy.ttf") format("truetype");
}
@font-face{
  font-family:"FrutigerNeueLTW04-Black";
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-Black.eot?#iefix");
  src:url("/assets/connexxion/fonts/FrutigerNeueLTW04-Black.eot?#iefix") format("eot"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Black.woff2") format("woff2"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Black.woff") format("woff"),url("/assets/connexxion/fonts/FrutigerNeueLTW04-Black.ttf") format("truetype");
}

body, h1, h2, h3, h4, h5, p {
  font-family: "FrutigerNeueLTW04-Book", serif;
}
