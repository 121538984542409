header {
  font-weight: 700;
  font-size: 16px !important;
}
.productSelectionModal {
  transform: translate3d(0, 0, 0) !important;
  max-width: 750px;
}
ngx-smart-modal.form {
  position: relative;
  display: contents;

  .wb-container {
    width: calc(100% - 40px);

    button {
      width: 48%;
    }
  }
}
.autocomplete-container .suggestions-container.is-visible {
  visibility: hidden !important;
}
.autocomplete-container.active .suggestions-container.is-visible {
  visibility: visible !important;
}

.country-select .flag.zw {
  height: 10px;
  background-position: -5610px 0px
}

.country-select {
  /*color: $ global-text-color;*/
  width: 10px;
}
.country-select.inside .selected-flag {
  width: 50px;
}
.country-select.inside input, .country-select.inside input {
  padding-right:0px;
  background-color: var(--header-background-color);
  border-color:var(--header-background-color);
  box-shadow: none;
}
.country-name{
  display: none;
}
